.slick-slide {
  padding: 0 10px;
}

.slick-prev, .slick-next {
  z-index: 1;
}

.slick-dots li button:before {
  color: #f5e300;
}

.slick-dots li.slick-active button:before {
  color: #f5e300;
}

.project-text-container {
  background-color: rgba(0, 0, 0, 0.7);
  color: rgb(245, 234, 151);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7);
  padding: 16px;
  border-radius: 0 0 8px 8px;
}
