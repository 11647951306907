@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.bg-custom-bg {
  background-color: #201c1c;
}
.text-pastel-yellow {
  color: #f7e7a7;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
